import React, { useState } from 'react';
import Layout from '../../components/Layout';

const OpportunitiesPage = () => {
	const [loading, setLoading] = useState(true);
	return (
		<Layout title='Opportunities'>
			<h2 className='raleway-700 my-3'>Internal Opportunities</h2>
			<p className='col-md-8 mb-4'>
				Every year at the ASGSR Meeting, our organization recognizes the most brilliant students in the field of
				space research with several scholarships, flight opportunities, and awards. Make sure to apply{' '}
				<a href='https://asgsr.org/awards' target='_blank'>
					here!
				</a>
			</p>

			<h2 className='raleway-700 my-3'>External Opportunities</h2>
			{loading && (
				<div className='d-flex align-items-middle my-3' style={{ width: '100%', height: '600px' }}>
					<div className='spinner-border me-3' role='status'>
						<span className='visually-hidden'>Loading...</span>
					</div>
					<span className='d-inline-block'>Loading opportunities...</span>
				</div>
			)}

			<iframe
				className={`airtable-embed mb-4${loading ? ' visually-hidden' : ''}`}
				src='https://airtable.com/embed/shr7fthtNSXSwaZsi?backgroundColor=orange'
				frameBorder='0'
				onLoad={() => setLoading(false)}
				width='100%'
				height='600'
				style={{ background: 'transparent', border: '1px solid #ccc' }}
			></iframe>
		</Layout>
	);
};

export default OpportunitiesPage;
